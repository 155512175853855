<template>
  <div class="error">
    <div class="content flex-box fdc aic jcfs">
      <svg class="icon" height="200" p-id="1445" t="1628577458964" version="1.1"
           viewBox="0 0 1690 1024" width="200" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M139.919128 135.073442m-87.099165 0a87.099165 87.099165 0 1 0 174.19833 0 87.099165 87.099165 0 1 0-174.19833 0Z"
          fill="#f4ea2a" p-id="1446"></path>
        <path d="M0 989.917661a840.701966 34.082282 0 1 0 1681.403933 0 840.701966 34.082282 0 1 0-1681.403933 0Z"
              fill="#e6e6e6" p-id="1447"></path>
        <path
          d="M213.960992 348.824259m-213.960992 0a213.960992 213.960992 0 1 0 427.921983 0 213.960992 213.960992 0 1 0-427.921983 0Z"
          fill="#04e7cb" p-id="1448"></path>
        <path d="M234.584559 993.286127h-43.824134l19.977898-676.684772 23.846236 676.684772z" fill="#3F3D56"
              p-id="1449"></path>
        <path
          d="M214.604768 444.849195l47.046803-65.089585L213.960992 460.960647l-5.155892-9.022338 5.799668-7.089114zM209.448876 510.584449l-47.044909-65.089584 47.690579 81.201036 5.155892-9.022337-5.801562-7.089115zM1566.847703 993.998068h-43.822241l19.977898-676.684772 23.844343 676.684772z"
          fill="#3F3D56" p-id="1450"></path>
        <path
          d="M1546.869805 445.561136l47.046804-65.089585-47.69058 81.201037-5.155892-9.022338 5.799668-7.089114zM1541.713913 511.29639l-47.044909-65.089584 47.690579 81.201036 5.155892-9.022337-5.801562-7.089115z"
          fill="#3F3D56" p-id="1451"></path>
        <path
          d="M869.204221 595.303535m-398.580925 0a398.580926 398.580926 0 1 0 797.161851 0 398.580926 398.580926 0 1 0-797.161851 0Z"
          fill="#3F3D56" p-id="1452"></path>
        <path
          d="M869.204221 595.303535m-365.58549 0a365.58549 365.58549 0 1 0 731.17098 0 365.58549 365.58549 0 1 0-731.17098 0Z"
          fill="#FFFFFF" p-id="1453"></path>
        <path
          d="M867.88448 596.623276m-48.832337 0a48.832336 48.832336 0 1 0 97.664673 0 48.832336 48.832336 0 1 0-97.664673 0Z"
          fill="#3F3D56" p-id="1454"></path>
        <path
          d="M857.324653 248.196322h21.11776v39.594144h-21.11776zM857.324653 905.45798h21.11776v39.594144h-21.11776zM559.049776 586.065343v21.11776h-39.594144v-21.11776zM1216.311434 586.065343v21.11776h-39.594144v-21.11776zM853.806604 617.822456l18.046568-41.264176 226.126473 125.237238-244.173041-83.973062zM817.730508 100.377679h102.943639v118.782432h-102.943639z"
          fill="#3F3D56" p-id="1455"></path>
        <path
          d="M750.421789 100.377679a118.782433 32.995436 0 1 0 237.564865 0 118.782433 32.995436 0 1 0-237.564865 0Z"
          fill="#3F3D56" p-id="1456"></path>
        <path
          d="M1091.589217 665.912555l-6.598709 9.240086s-30.354059 35.634919-31.6738 18.476383 22.437502-29.036211 22.437502-29.03621l13.197417-7.91845zM1279.458328 697.082696l5.525117 9.917944s19.214833 42.686165 3.143144 36.536206-16.639727-32.703843-16.639728-32.703843l-1.509087-15.318092z"
          fill="#FFB8B8" p-id="1457"></path>
        <path
          d="M1249.746152 481.031324m-40.186797 0a40.186797 40.186797 0 1 0 80.373595 0 40.186797 40.186797 0 1 0-80.373595 0Z"
          fill="#2F2E41" p-id="1458"></path>
        <path
          d="M1246.006569 811.091715l5.278967 34.315178v10.557933h30.355952v-13.197417s1.319742-23.757244-3.959225-30.355952-31.675694-1.319742-31.675694-1.319742zM1191.895266 811.091715l5.278967 34.315178v10.557933H1227.530185v-13.197417s1.319742-23.757244-3.959225-30.355952-31.677588-1.319742-31.677588-1.319742z"
          fill="#FFB8B8" p-id="1459"></path>
        <path
          d="M1212.321914 535.455048s-11.675075 6.153745-3.699822 20.152096 54.762653 158.81586 54.762654 158.815859l26.993167-7.662833-23.804581-91.586665-13.632912-57.564974z"
          fill="#04e7cb" p-id="1460"></path>
        <path
          d="M1245.347645 492.359896m-30.355953 0a30.355952 30.355952 0 1 0 60.711905 0 30.355952 30.355952 0 1 0-60.711905 0Z"
          fill="#FFB8B8" p-id="1461"></path>
        <path
          d="M1259.203986 496.978045l-3.957332 62.029753L1227.530185 539.213566s9.238192-36.952767 6.598709-39.592251z"
          fill="#FFB8B8" p-id="1462"></path>
        <path
          d="M1259.203986 553.730725L1229.347907 531.620791s-13.695397-5.604642-17.654622 0.992173-23.75535 104.265274-13.197417 122.741658c0 0 56.750786 11.879569 68.630355 5.278967l5.278967-34.313284s6.598708-21.117761-3.959226-34.315178z"
          fill="#04e7cb" p-id="1463"></path>
        <path
          d="M1219.611735 533.932706s-10.559827-7.91845-19.798019 5.278967-120.102174 117.462691-120.102174 117.462691l18.478277 21.11776 72.587686-60.711905 46.192853-36.954661z"
          fill="#04e7cb" p-id="1464"></path>
        <path
          d="M1267.785147 659.974664s-65.331947-12.538493-71.930656-3.3003l-1.319742 14.519052s-14.517159 27.714576-6.598708 72.587686 2.639483 72.58958 2.639483 72.58958 35.634919-3.959225 62.029753 0 30.355952 0 30.355953 0zM1284.280971 850.68586l-38.274402 1.319741 13.197417 98.984414s-10.557934 42.233628 1.319741 44.875005a133.892244 133.892244 0 0 0 25.076986 2.637589s11.877675 11.879569 25.076986 10.559827a73.909322 73.909322 0 0 0 25.075092-7.920343s2.639483-7.91845-6.598709-10.557934-34.315177-10.557934-39.59225-38.274402z"
          fill="#2F2E41" p-id="1465"></path>
        <path
          d="M1230.169668 850.68586l-38.274402 1.319741 13.197417 98.984414s-10.557934 42.233628 1.319742 44.875005a133.892244 133.892244 0 0 0 25.076985 2.637589s11.877675 11.879569 25.075092 10.559827a73.909322 73.909322 0 0 0 25.076986-7.920343s2.639483-7.91845-6.598709-10.557934-34.315177-10.557934-39.594144-38.274402z"
          fill="#2F2E41" p-id="1466"></path>
        <path
          d="M1263.805094 438.735212m-16.628367 0a16.628367 16.628367 0 1 0 33.256733 0 16.628367 16.628367 0 1 0-33.256733 0Z"
          fill="#2F2E41" p-id="1467"></path>
        <path
          d="M1285.977512 429.728022a16.63026 16.63026 0 0 0-14.897745-16.539374 16.815819 16.815819 0 0 1 1.732516-0.090886 16.628367 16.628367 0 0 1 0 33.258627 16.815819 16.815819 0 0 1-1.732516-0.088993 16.63026 16.63026 0 0 0 14.897745-16.539374z"
          fill="#2F2E41" p-id="1468"></path>
        <path d="M1217.358517 472.510753a27.714576 16.628367 0 1 0 55.429151 0 27.714576 16.628367 0 1 0-55.429151 0Z"
              fill="#2F2E41" p-id="1469"></path>
        <path
          d="M1681.619787 79.824169a84.594117 84.594117 0 0 0-165.975032-17.836394c-1.024362-0.035976-2.048724-0.075738-3.082553-0.075738a84.614945 84.614945 0 0 0-81.166955 60.759241 59.784109 59.784109 0 0 0-70.938482 17.645155h263.876387a57.220364 57.220364 0 0 0 57.345332-59.244474q-0.024615-0.622948-0.058697-1.24779z"
          fill="#E6E6E6" p-id="1470"></path>
        <path
          d="M1461.020324 235.139021m-22.721521 0a22.721521 22.721521 0 1 0 45.443043 0 22.721521 22.721521 0 1 0-45.443043 0Z"
          fill="#04e7cb" p-id="1471"></path>
        <path
          d="M1529.626064 181.569248m-18.919453 0a18.919453 18.919453 0 1 0 37.838907 0 18.919453 18.919453 0 1 0-37.838907 0Z"
          fill="#04e7cb" p-id="1472"></path>
        <path
          d="M1621.964433 284.368984m-22.721521 0a22.721521 22.721521 0 1 0 45.443042 0 22.721521 22.721521 0 1 0-45.443042 0Z"
          fill="#04e7cb" p-id="1473"></path>
        <path
          d="M1449.659564 388.509289m-22.721522 0a22.721521 22.721521 0 1 0 45.443043 0 22.721521 22.721521 0 1 0-45.443043 0Z"
          fill="#04e7cb" p-id="1474"></path>
        <path
          d="M1621.964433 519.158037m-22.721521 0a22.721521 22.721521 0 1 0 45.443042 0 22.721521 22.721521 0 1 0-45.443042 0Z"
          fill="#04e7cb" p-id="1475"></path>
        <path
          d="M1040.0419609 986.93700028c0.003724-0.12102744 0.01861949-0.23926165 0.01861949-0.36122035a11.15499846 11.15499846 0 0 0-15.81455183-10.15233315 11.14196497 11.14196497 0 0 0-19.39416861 9.22973278c-0.05585898 0-0.11171745-0.00837876-0.16850719-0.00837927a11.17175598 11.17175598 0 1 0 6.82780521 20.00023664 11.16616997 11.16616997 0 0 0 19.19866272 1.13300229 11.16244646 11.16244646 0 1 0 9.33214021-19.84103894z"
          fill="#E6E6E6" p-id="1476"></path>
        <path
          d="M1642.792494 428.271952m-9.4673 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#04e7cb" p-id="1477"></path>
        <path
          d="M1582.201771 201.056739m-9.467301 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#E6E6E6" p-id="1478"></path>
        <path
          d="M1473.96298083 449.25086621m-7.80081482 0a7.80081564 7.80081564 0 1 0 15.60163046 0 7.80081564 7.80081564 0 1 0-15.60163046 0Z"
          fill="#04e7cb" p-id="1479"></path>
        <path
          d="M1557.58679 426.378492m-9.467301 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#04e7cb" p-id="1480"></path>
        <path
          d="M1563.26717 416.911191m-9.467301 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#E6E6E6" p-id="1481"></path>
        <path
          d="M1466.16216601 499.17608352m-7.80081564 0a7.80081564 7.80081564 0 1 0 15.60163046 0 7.80081564 7.80081564 0 1 0-15.60163046 0Z"
          fill="#04e7cb" p-id="1482"></path>
        <path
          d="M1680.661696 602.470281m-9.4673 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#E6E6E6" p-id="1483"></path>
        <path
          d="M1478.061465 608.150661m-9.4673 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#E6E6E6" p-id="1484"></path>
        <path
          d="M1489.422226 316.557806m-9.467301 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#04e7cb" p-id="1485"></path>
        <path
          d="M1459.92151333 491.37526871m-7.80081482 0a7.80081564 7.80081564 0 1 0 15.60163047 0 7.80081564 7.80081564 0 1 0-15.60163047 0Z"
          fill="#04e7cb" p-id="1486"></path>
        <path
          d="M1523.504508 471.821534m-9.467301 0a9.467301 9.467301 0 1 0 18.934601 0 9.467301 9.467301 0 1 0-18.934601 0Z"
          fill="#04e7cb" p-id="1487"></path>
      </svg>
      <span class="fs26"
            style="color:#A9A9A9">亲爱的面试者，Hi~<br />此二维码已经超时失效，<br />若没有及时提交填写内容，<br />请联系人事小姐姐哦(●'◡'●)</span></div>
    <p
      v-if="opendId && phoneNo"
      class="link-btn"
    >
      <router-link
        :to="{name: 'index'}"
        class="btn-home"
      >
        返回首页
      </router-link>
    </p>
  </div>
</template>
<script>
import mixin from "@/mixins/mixins"

export default {
  mixins: [mixin]
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.error {
  background: $color-err-bg;
  width: 100%;
  height: 100vh;

  .content {
    text-align: center;
    height: 60vh;
    padding-top: 2.5rem;
    box-sizing: border-box;

    img {
      width: 80%;
    }
  }

  .link-btn {
    margin-top: 2rem;
    text-align: center;

    .btn-home {
      background: $color-active;
      color: $color-white;
      font-size: .16rem;
      display: inline-block;
      width: 70%;
      padding: .15rem 0;
      border-radius: .1rem;
    }
  }
}

</style>
