import axios from "axios"
import { showFullScreenLoading, tryHideFullScreenLoading } from "./loading"
import router from "../router"
import { Toast } from "vant"
import { getToken, getToken2 } from "@/utils/auth"
import NProgress from "nprogress"

Toast.allowMultiple()

function toastBox (msg = "请求失败") {
  const toast = Toast.fail({
    message: msg,
    duration: 0
  })
  setTimeout(() => {
    toast.clear()
  }, 1500)
}

const http = axios.create({
  baseURL: "/api/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  },
  transformRequest: [function(data) {
    return data
  }]
})
http.defaults.timeout = 15000
http.interceptors.request.use(function(config) {
  // const openId = getToken()
  // toastBox(`openId:${openId}`)
  // if (openId) config.headers.Authorization = "Bearer " + getToken()

  const openId = window.location.href.indexOf("/u9762u8bd5u5165u804c") > -1
  if (openId) {
    config.headers.Authorization = "Bearer " + getToken()
  } else {
    if (getToken2()) {
      config.headers.Authorization = "Bearer " + getToken2()
    }
  }

  // 通过session控制是否需要加载框
  const loading = localStorage.getItem("loading")
  if (!config.showLoading && loading === "0") {
    config.showLoading = true
    showFullScreenLoading()
  }
  return config
}, function(error) {
  return Promise.reject(error)
})
http.interceptors.response.use(function(response) {
  if (response.config.showLoading) {
    tryHideFullScreenLoading()
  }
  const code = response.data.code
  if (code === 200) {
    return response
  } else {
    toastBox(response.data.message || response.data.msg)
    // toastBox(code)
    if ([401].includes(code)) {
      router.push({ path: "/network" })
    }
    return response
    // return Promise.reject(response);
  }
}, function(r) {
  let { error, config, code, request, response } = r
  console.log(error, config, code, request, response)
  // if (response.state === 500) {
  router.push({ name: "djnetwork" })
  // }
  // toastBox()
  tryHideFullScreenLoading()
  NProgress.done()
  // router.push({ name: 'djerror' })
  return Promise.reject(error)
})

export default http
