import { getToken } from "@/utils/auth"

let BASE, wxDomain, wxApi, diagImg,onlySave

switch (process.env.NODE_ENV) {
  case "production":
    BASE = "https://erp.dijianggroup.com/api/"
    wxApi = "https://erp.dijianggroup.com/api/"
    wxDomain = "https://erp.dijianggroup.com/api/"
    diagImg = "https://erp.dijianggroup.com/api/"
    onlySave = "https://erp.dijianggroup.com/api/"
    break
  case "uat":
    BASE = "https://erp.dijianggroup.com/api/"
    wxApi = "https://erp.dijianggroup.com/api/"
    wxDomain = "https://erp.dijianggroup.com/api/"
    diagImg = "https://erp.dijianggroup.com/api/"
    onlySave = "https://erp.dijianggroup.com/api/"
    break
  case "development":
    BASE = "https://erp.dijianggroup.com/api/"
    wxApi = "https://erp.dijianggroup.com/api/"
    wxDomain = "https://erp.dijianggroup.com/api/"
    diagImg = "https://erp.dijianggroup.com/api/"
    onlySave = "https://erp.dijianggroup.com/api/"
    break
  default:
    BASE = "https://erp.dijianggroup.com/api/"
    wxApi = "https://erp.dijianggroup.com/api/"
    wxDomain = "https://erp.dijianggroup.com/api/"
    diagImg = "https://erp.dijianggroup.com/api/"
    onlySave = "https://erp.dijianggroup.com/api/"
}

const uploadUrl = BASE + "BASE-API/fileUpload"
const shopFileUpload = BASE + "BASE-API/shopFileUpload"

const SECRET = "HR_SECRET"

const imgDomain = "https://dijianggroup.oss-cn-hangzhou.aliyuncs.com/"
const token = getToken()

function config (url, params, method = "GET") {
  return {
    method: method,
    url: url,
    data: method === "POST" || method === "PUT" ? JSON.stringify(params) : null,
    params: method === "GET" || method === "DELETE" ? params : null
  }
}

const cb = (res, callback) => {
  if (res && res.data) {
    if (typeof callback === "function") callback(res)
  }
}

export {
  BASE,
  config,
  cb,
  uploadUrl,
  token,
  imgDomain,
  shopFileUpload,
  wxDomain,
  wxApi,
  diagImg,
  SECRET,
  onlySave
}
