import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect (to) {
        if (to.path === "/") {
          return "/index"
        } else {
          return "/network"
        }
      }
    },
    {
      name: "djnetwork",
      path: "/network",
      component: () => import("@/components/djnetwork")
    },
    {
      name: "loading",
      path: "/loading",
      component: () => import("@/components/index/loading")
    },
    {
      name: "djwait",
      path: "/wait",
      component: () => import("@/components/kmWait")
    },
    {
      name: "djAuthor",
      path: "/author",
      component: () => import("@/components/djAuthor")
    },
    {
      name: "djerror",
      path: "/error",
      // component: () => import("@/components/djError")
      component: require("@/components/djError.vue").default
    },
    {
      name: "djinvalid",
      path: "/invalid",
      // component: () => import("@/components/djError")
      component: require("@/components/djinvalid.vue").default
    },
    // 首页
    {
      name: "index",
      path: "/index",
      component: () => import("@/components/index/index"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "首页"
      }
    },
    // 指导价
    {
      name: "write",
      path: "/write",
      component: resolve => require(["@/components/write/index"], resolve),
      redirect: "/write/interview",
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "面试入职管理"
      },
      children: [
        {
          name: "writeindex",
          path: "index",
          component: () => import("@/components/write/index"),
          meta: {
            login: false,
            keepAlive: true,
            position_: 0,
            title: "面试入职管理"
          }
        },
        {
          name: "entry",
          path: "entry/:id?/:d?",
          component: () => import("@/components/write/entry"),
          meta: {
            login: false,
            keepAlive: true,
            position_: 0,
            title: "面试入职管理"
          }
        },
        {
          name: "interview",
          path: "interview/:id?/:d?",
          component: () => import("@/components/write/interview"),
          meta: {
            login: false,
            keepAlive: true,
            position_: 0,
            title: "面试入职管理"
          }
        },
        {
          name: "succ",
          path: "succ",
          component: () => import("@/components/write/succ"),
          meta: {
            login: false,
            keepAlive: true,
            position_: 0,
            title: "面试入职管理"
          }
        },
      ]
    },
    // 指导价
    {
      name: "u9762u8bd5u5165u804c",
      path: "/u9762u8bd5u5165u804c",
      component: resolve => require(["@/components/u9762u8bd5u5165u804c/index"], resolve),
      redirect: "/u9762u8bd5u5165u804c/choose",
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "面试入职管理"
      },
      children: [
        {
          name: "u9762index",
          path: "index",
          component: () => import("@/components/u9762u8bd5u5165u804c/index"),
          meta: {
            login: false,
            keepAlive: true,
            position_: 0,
            title: "面试入职管理"
          }
        },
        {
          name: "u9762choose",
          path: "choose",
          component: () => import("@/components/u9762u8bd5u5165u804c/choose"),
          meta: {
            login: false,
            keepAlive: true,
            position_: 0,
            title: "面试入职管理"
          }
        },
        {
          name: "u9762send",
          path: "send",
          component: () => import("@/components/u9762u8bd5u5165u804c/send"),
          meta: {
            login: false,
            keepAlive: true,
            position_: 0,
            title: "面试入职管理"
          }
        }
      ]
    },
    // 指导价
    {
      name: "price",
      path: "/price",
      component: () => import("@/components/price/index"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "指导价列表"
      }
    },
    {
      name: "steps",
      path: "/steps",
      component: () => import("@/components/price/steps"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "历史指导价"
      }
    },
    {
      name: "pricedtl",
      path: "/pricedtl",
      component: () => import("@/components/price/pricedtl"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "指导价详情"
      }
    },
    // 供应商
    {
      name: "provider",
      path: "/provider",
      component: () => import("@/components/provider/index"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "供应商列表页"
      }
    },
    // 供应商信息变更记录
    {
      name: "changeLog",
      path: "/changeLog",
      component: () => import("@/components/provider/changeLog"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "供应商信息变更记录"
      }
    },
    // 供应商变更详情
    {
      name: "providerDtl",
      path: "/providerDtl",
      component: () => import("@/components/provider/providerDtl"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "供应商详情页"
      }
    },
    // 合同
    {
      name: "contract",
      path: "/contract",
      component: () => import("@/components/contract/index"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "合同列表页"
      }
    },
    {
      name: "contractDtl",
      path: "/contractDtl",
      component: () => import("@/components/contract/contractDtl"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "合同详情"
      }
    }
  ],
  linkActiveClass: "active",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err);
// };

export default router
