<template>
  <div class="layout-header bsbb w100per tabBarBox h100">
    <div class="flex-box jcsb bsbb h100  pl15 pr15">
      <slot
        class=""
        name="leftHandle"
      />
      <slot
        class=""
        name="headerTitle"
      />
      <slot
        class=""
        name="rightHandle"
      />
    </div>
    <div class="bgc_fff w100per val-filter--footer">
      <slot
        class=""
        name="footer"
      />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  created () {
  },
  methods: {
    name () {
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  //background: linear-gradient(to bottom right, #D81E06, #A64D44);
  background: #fff;
  //color: white;
  height: 1.1rem;
  line-height: 1;
  box-sizing: border-box;
  //display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  //width: calc(100vw - 0.06rem);
  z-index: 99;
  border-bottom: 5px solid #E1ECF7;
}
</style>
