import { apiBegin } from "@/api/h5"
import { getToken, setToken } from "@/utils/auth"
import dayjs from "dayjs"

export default {
  data () {
    return {
      // STICKY_OFFSET_TOP: '1.2rem',
      STICKY_OFFSET_TOP: '1rem',
      activeName: 1
    }
  },
  created () {
  },
  methods: {
    // 提交时判断工作经历的时间是否连贯 p0e1
    judgeFullCoherent () {
      // p0e1 0 -> 1 => [ work[当前行下标-1].timeRange[0], work[当前行下标].timeRange[1]]
      let p0e1 = {};
      // 1.去重
      let lts = {};
      let temp_lst = [];
      this.form.work.forEach((ls) => {
        if (lts.hasOwnProperty(ls.timeRange + "")) {
          lts[ls.timeRange + ""]++;
        } else {
          if (ls.timeRange.length === 2) {
            temp_lst.push(ls);
            lts[ls.timeRange + ""] = 1;
          }
        }
      });
      // 2.排序
      let sort_temp_lst__b1a1 = temp_lst.sort((a, b) => {
        return dayjs(b.timeRange[1]).valueOf() - dayjs(a.timeRange[1]).valueOf();
      });
      sort_temp_lst__b1a1.forEach((el, e) => {
        if (e === 0) {
        } else {
          var p0 = sort_temp_lst__b1a1[e - 1].timeRange ? sort_temp_lst__b1a1[e - 1].timeRange[0] : undefined
          var e1 = el.timeRange ? el.timeRange[1] : undefined
          // 上0下1 => p0e1
          p0e1[e] = [p0, e1]
        }
      })
      let is_coherent__p0e1 = false
      for (var k2 in p0e1) {
        if (p0e1.hasOwnProperty(k2)) {
          if (this.judgeCoherent(p0e1[k2], "subtract")) {
            is_coherent__p0e1 = true
          }
        }
      }
      console.log("p0e1 => 数组", p0e1)
      console.log("时间是否连续 => is_coherent__p0e1", is_coherent__p0e1)
      if (is_coherent__p0e1) {
        this.$msg("工作经历时间不连贯,请修改")
        return is_coherent__p0e1
      }
    },
    // 判断连贯
    judgeCoherent (timeRange, rule = "add") {
      let is_valid_timeRanges = timeRange && Array.isArray(timeRange) && timeRange.length === 2
      if (!is_valid_timeRanges) {
        return true
      }

      let prev_end = timeRange[0]
      let e_end = timeRange[1]
      let is_valid = prev_end && dayjs(prev_end).isValid() && e_end && dayjs(e_end).isValid()
      if (!is_valid) {
        return true
      }
      let is_eq = dayjs(prev_end).valueOf() === dayjs(e_end).valueOf()
      let is_add1_day = false
      if (rule === "add") {
        is_add1_day = dayjs(prev_end).add(1, "month").valueOf() === dayjs(e_end).valueOf()
      } else if (rule === "subtract") {
        is_add1_day = dayjs(prev_end).subtract(1, "month").valueOf() === dayjs(e_end).valueOf()
      } else {
        is_add1_day = false
      }
      return !(is_add1_day || is_eq)
    },

    // 提交时判断工作经历的时间是否连贯
    judgeFullCoherent2 () {
      // 0 -> 1
      // 1 -> 0
      let obj = {}
      let obj2 = {}
      let last = this.form.work.length - 1
      this.form.work.forEach((el, e) => {
        if (e === 0) {
        } else {
          obj[e] = [this.form.work[e - 1].timeRange[1], el.timeRange[0]]
          obj2[e] = [this.form.work[e - 1].timeRange[0], el.timeRange[1]]
        }
      })
      let is_coherent_full__list = false
      for (var k in obj) {
        if (obj.hasOwnProperty(k)) {
          if (this.judgeCoherent(obj[k][0], obj[k][1])) {
            is_coherent_full__list = true
          }
        }
      }
      let is_coherent_full__list2 = false
      for (var k2 in obj2) {
        if (obj2.hasOwnProperty(k2)) {
          if (this.judgeCoherent2(obj2[k2][0], obj[k2][1])) {
            is_coherent_full__list2 = true
          }
        }
      }
      if (is_coherent_full__list && is_coherent_full__list2) {
        this.$msg("工作经历时间不连贯,请修改")
        return is_coherent_full__list && is_coherent_full__list2
      }
    },
    // 删除工作经历时删除对应的项目经历
    delProjectByWorkId (i_) {
      console.log("过滤之后的project ", i_, this.form.project.filter(el => String(el.work_id) !== String(i_)))
      this.form.project = this.form.project.filter(el => String(el.work_id) !== String(i_))
    },
    // 判断连贯
    judgeCoherent3 (prev_end, e_end) {
      let is_add1_day = dayjs(prev_end).add(1, "day").valueOf() === dayjs(e_end).valueOf()
      let is_eq = dayjs(prev_end).valueOf() === dayjs(e_end).valueOf()
      return !(is_add1_day || is_eq)
    },
    // 判断连贯-反向递减
    judgeCoherent2 (prev_end, e_end) {
      let is_add1_day = dayjs(prev_end).subtract(1, "month").valueOf() === dayjs(e_end).valueOf()
      let is_eq = dayjs(prev_end).valueOf() === dayjs(e_end).valueOf()
      return !(is_add1_day || is_eq)
    },
    // 去需要判断的时间
    getTime (i, index) {
      // work   panel
      // 1    > 0
      // 0    > 1
      let index_ = Math.abs(index - 1)
      if (index === 0) {
        return { start: this.form.work[i - 1].timeRange[1], end: this.panelWork.timeRange[0] }
      }
      if (index === 1) {
        return { start: this.panelWork.timeRange[1], end: this.form.work[i + 1].timeRange[0] }
      }
    },
    dev8080SetToken () {
      setToken("M27LwYmiJQZ5Jvac628WKueQYqU", 999999999)
    },
    // 判断顶部筛选区域的过滤条件样式
    judgeFilterClass ({ filed, def = "" }) {
      if (filed === "") {
        return "filter-params-0"
      } else {
        return "filter-params-1"
      }
    },
    // 获取new Date需要的时间
    getYMD (val, type = "") {
      let val_ = val
      if (!dayjs(val).isValid()) {
        val_ = dayjs().valueOf()
      }
      let y = dayjs(val_).format("YYYY")
      let m = dayjs(val_).format("MM") - 1
      let d = dayjs(val_).format("DD")
      if (type) {
        if (type === "y") {
          return y
        }
        if (type === "m") {
          return m
        }
        if (type === "d") {
          return d
        }
        if (type === "ymd") {
          return { y, m, d }
        }
      } else {
        return new Date(y, m, d)
      }
    },
    // 获取new Date需要的时间 - 年月
    getYM (val, type = "") {
      let val_ = val
      if (!dayjs(val).isValid()) {
        val_ = dayjs().valueOf()
      }
      let y = dayjs(val_).format("YYYY")
      let m = dayjs(val_).format("MM") - 1
      if (type) {
        if (type === "y") {
          return y
        }
        if (type === "m") {
          return m
        }
        if (type === "ym") {
          return { y, m }
        }
      } else {
        return new Date(y, m)
      }
    },
    // 首字母大写
    upper (str) {
      if (str) {
        return str.replace(str[0], str[0].toUpperCase())
      } else {
        return ""
      }
    }
  }
}
