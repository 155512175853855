import Cookies from "js-cookie"

const TokenKey = "h-token"
const TokenKey2 = "w-token"

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token, expire) {
  console.log("token", token)
  return Cookies.set(TokenKey, token, { expires: expire })
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getToken2 () {
  return Cookies.get(TokenKey2)
}

export function setToken2 (token, expire) {
  return Cookies.set(TokenKey2, token, { expires: expire })
}

export function removeToken2 () {
  return Cookies.remove(TokenKey2)
}
